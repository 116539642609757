import * as Sentry from '@sentry/svelte';
import { version } from '$app/environment';
import type { NavigationEvent } from '@sveltejs/kit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { env } from '$env/dynamic/public';

Sentry.init({
  environment: env.PUBLIC_DEPLOYMENT ?? 'local',
  dsn: 'https://0549577555a81ee0edb90bcd209fa006@o4507112824176640.ingest.us.sentry.io/4507112828370944',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: version
});

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event }: { error: unknown; event: NavigationEvent }) {
  const errorId = crypto.randomUUID();
  Sentry.captureException(error, { extra: { event, errorId } });
}
